import {DEFAULT_VIDEO_VARIANT_NAME} from '../consts/videoVariants';
import {LightboxVideo} from '../types/LightboxVideo';
import {orderVideoVariants} from './orderVideoVariants';

export function getVideoVariant(video: LightboxVideo, preferredType: string) {
  const videoVariants = orderVideoVariants(video.variants);

  return (
    videoVariants?.find((variant) => variant.type === preferredType) ??
    videoVariants?.find((variant) => variant.type === DEFAULT_VIDEO_VARIANT_NAME) ?? {
      type: 'original',
      url: video.originUrl,
    }
  );
}
