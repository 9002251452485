import {isNil} from 'ramda';

import {DEFAULT_VIDEO_VARIANT_NAME} from '../consts/videoVariants';
import {LightboxVideo} from '../types/LightboxVideo';

type VideoVariant = Pick<NonNullable<LightboxVideo['variants']>[number], 'type'>;

function getPixelsFromVideoVariantType(variantType: string) {
  if (variantType.includes('p')) {
    return Number(variantType.replace('p', ''));
  }
  if (variantType.includes('k')) {
    return Number(variantType.replace('k', '')) * 1000;
  }
  return 0;
}

export function orderVideoVariants<TVideoVariant extends VideoVariant>(
  variants: TVideoVariant[] | null
) {
  if (isNil(variants)) {
    return [];
  }

  return variants.toSorted((a, b) => {
    // DEFAULT_VIDEO_VARIANT_NAME should be the first one
    if (a.type === DEFAULT_VIDEO_VARIANT_NAME) {
      return -1;
    }
    if (b.type === DEFAULT_VIDEO_VARIANT_NAME) {
      return 1;
    }

    const videoA = getPixelsFromVideoVariantType(a.type);
    const videoB = getPixelsFromVideoVariantType(b.type);

    return videoB - videoA;
  });
}
