import {colord} from 'colord';
import {isTouchable} from 'platform/foundation';
import {css, useTheme} from 'styled-components';
import {match} from 'ts-pattern';

import {ButtonHTMLAttributes} from 'react';

import {always} from 'ramda';

import {RequiredTestIdProps, suffixTestId} from 'shared';

type ButtonVariant = 'primary' | 'ghost';

export interface ButtonProps extends RequiredTestIdProps {
  title: string;
  variant?: ButtonVariant;
  isDisabled?: boolean;
  isActive?: boolean;
  isFullWidth?: boolean;
  justifyContent?: 'start' | 'center' | 'end';
  size?: 'small' | 'default';
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
}

export function Button(props: ButtonProps) {
  const theme = useTheme();
  const isTouchableDevice = isTouchable();

  const variant = props.variant ?? 'primary';
  const size = props.size ?? 'default';

  const heightStyle = match({size})
    .with({size: 'small'}, always(theme.getSize(6)))
    .otherwise(always(theme.getSize(9)));
  const paddingXStyle = match({size})
    .with({size: 'small'}, always(theme.getSize(1)))
    .otherwise(always(theme.getSize(2)));
  const colorStyle = match({isActive: props.isActive})
    .with({isActive: true}, always(theme.colors.general.white))
    .otherwise(always(colord(theme.colors.general.white).alpha(0.6).toRgbString()));
  const backgroundColorStyle = match({variant})
    .with({variant: 'ghost'}, always('transparent'))
    .otherwise(always(colord(theme.colors.general.black).alpha(0.7).toRgbString()));
  const widthStyle = props.isFullWidth ? '100%' : 'auto';
  const justifyContentStyle = match(props.justifyContent)
    .with('start', always('flex-start'))
    .with('center', always('center'))
    .with('end', always('flex-end'))
    .otherwise(always('center'));

  return (
    <button
      data-testid={suffixTestId('button', props)}
      onClick={props.onClick}
      disabled={props.isDisabled}
      type="button"
      css={css`
        display: flex;
        flex-shrink: 0;
        width: ${widthStyle};
        align-items: center;
        justify-content: ${justifyContentStyle};
        border: none;
        height: ${heightStyle};
        padding: 0 ${paddingXStyle};
        cursor: ${props.isDisabled ? 'not-allowed' : 'pointer'};
        pointer-events: ${props.isDisabled ? 'none' : 'all'};
        color: ${colorStyle};
        background-color: ${backgroundColorStyle};
        opacity: ${props.isDisabled ? 0.2 : 1};
        transition: opacity 0.3s ease-out;
        border-radius: ${({theme}) => theme.radii.small};
        ${!isTouchableDevice &&
        `
          &:hover {
            color: ${theme.colors.general.white};
          }
        `}
      `}
    >
      {props.title}
    </button>
  );
}
