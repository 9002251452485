import {useTranslationContext} from 'platform/components';
import styled from 'styled-components';

import {uniqBy} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {LightboxVideo} from '../types/LightboxVideo';
import {Select} from './Select';

interface SlideshowVideoProps extends RequiredTestIdProps {
  videoVariants: NonNullable<LightboxVideo['variants']>;
  isVisible: boolean;
  value: string;
  onChange: (value: string) => void;
}

export function VideoQualitySelect(props: SlideshowVideoProps) {
  const t = useTranslationContext();

  const options = uniqBy((variant) => variant.type, props.videoVariants).map((variant) => {
    const label = variant.type === 'default' ? t('general.labels.default') : variant.type;
    const isSelected =
      props.value === variant.type ?? (variant.type === 'default' && isNilOrEmpty(props.value));

    return {
      label,
      value: variant.type,
      isSelected,
      onClick: () => {
        props.onChange(variant.type);
      },
    };
  });

  return (
    <StyledVideoQuality isVisible={props.isVisible}>
      <Select
        value={props.value}
        options={options}
        placeholder={t('general.labels.default')}
        data-testid={suffixTestId('videoQuality', props)}
      />
    </StyledVideoQuality>
  );
}

const StyledVideoQuality = styled.div<{isVisible: boolean}>`
  z-index: ${({theme}) => theme.zIndices.LIGHTBOX_ACTIONS};
  opacity: ${({isVisible}) => (isVisible ? 1 : 0)};
  transition: opacity 0.15s ease-out;
`;
