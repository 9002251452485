import {showNotification, useTranslationContext} from 'platform/components';
import {
  getSize,
  getValueByDevice,
  Show,
  useDevice,
  useIsInViewport,
  useWindowDimensions,
} from 'platform/foundation';
import {css} from 'styled-components';

import {useEffect, useRef, useState} from 'react';

import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {RequiredTestIdProps, suffixTestId, useBoolean} from 'shared';

import {platformLightboxQueryParams} from '../consts/platformLightboxQueryParams';
import {spaceBetweenSlides} from '../consts/spaceBetweenSlides';
import {LightboxAction} from '../types/LightboxAction';
import {LightboxVideo} from '../types/LightboxVideo';
import {orderVideoVariants} from '../utils/orderVideoVariants';
import {Actions} from './Actions';
import {SlideshowActionsWrapper} from './SlideshowActionsWrapper';
import {VideoItem} from './VideoItem';
import {VideoQualitySelect} from './VideoQualitySelect';

interface SlideshowVideoProps extends RequiredTestIdProps {
  activeIndex: number;
  index: number;
  video: LightboxVideo;
  onSlideshowItemInView: () => void;
}

export function SlideshowVideo(props: SlideshowVideoProps) {
  const device = useDevice();
  const windowDimensions = useWindowDimensions();
  const t = useTranslationContext();

  const boxRef = useRef<HTMLDivElement>(null);
  const isInView = useIsInViewport(boxRef, 0.85);

  const [isHovered, onMouseEnter, onMouseLeave] = useBoolean();

  const [videoVariant, setVideoVariant] = useState('default');

  /**
   * @description
   * Whenever a image item is inView, we trigger a callback to level up,
   * which sets the pointer to this element for comparison.
   * If the pointer is not equal to the activeIndex from controls,
   * then scrollIntoView is triggered to this element
   */
  useEffect(() => {
    if (isInView && device !== 'mobile' && device !== 'tablet') {
      props.onSlideshowItemInView();
    }
  }, [device, isInView, props]);

  const actions: LightboxAction[] = [
    ...(props.video.actions ?? []),
    {
      icon: 'social/share',
      onClick: async () => {
        const url =
          window.location.href +
          `&${platformLightboxQueryParams.LIGHTBOX_DETAIL_INDEX}=${props.activeIndex}`;
        if (navigator.share) {
          try {
            await navigator.share({
              title: document.title,
              url,
            });
          } catch (err: unknown) {
            if (!(err instanceof Error && err.toString().includes('AbortError'))) {
              showNotification.error(t('platform.lightbox.share-error'));
            }
          }
        } else {
          await navigator.clipboard.writeText(url);
          showNotification.info(t('platform.lightbox.copy-to-clipboard'));
        }
      },
      id: 'shareBtn',
    },
  ];

  const handleMouseEnter = () => {
    if (isNilOrEmpty(actions) || device === 'mobile' || device === 'tablet') {
      return;
    }
    onMouseEnter();
  };

  const handleMouseLeave = () => {
    if (isNilOrEmpty(actions) || device === 'mobile' || device === 'tablet') {
      return;
    }
    onMouseLeave();
  };

  return (
    <div
      ref={boxRef}
      data-testid={suffixTestId('slideshowItem', props)}
      css={css`
        position: relative;
        height: ${getValueByDevice(
          device,
          'auto',
          'auto',
          `calc(${windowDimensions.height}px - ${getSize(spaceBetweenSlides)} - ${getSize(spaceBetweenSlides)})`
        )};
        width: 100%;
        aspect-ratio: ${getValueByDevice(device, 4 / 3, 4 / 3, undefined)};
        background-color: ${({theme}) => theme.colors.palettes.neutral['10']['40']};
        border-radius: ${({theme}) => theme.radii.medium};
      `}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Show when={props.video.variants && props.video.variants.length > 1}>
        <SlideshowActionsWrapper isHidden={!isHovered} spacingFromEdge={4} position="left">
          <VideoQualitySelect
            value={videoVariant}
            isVisible={isHovered}
            videoVariants={orderVideoVariants(props.video.variants ?? [])}
            onChange={setVideoVariant}
            data-testid={suffixTestId('videoQuality', props)}
          />
        </SlideshowActionsWrapper>
      </Show>
      <Show when={isNotNilOrEmpty(actions)}>
        <SlideshowActionsWrapper isHidden={isHovered} position="right" spacingFromEdge={24}>
          <Actions
            data-testid={suffixTestId('slideshowItem', props)}
            disablePosition
            zIndex="LIGHTBOX_ACTIONS"
            actions={[actions]}
            isHidden={!isHovered}
          />
        </SlideshowActionsWrapper>
      </Show>
      <VideoItem
        preferredVariantType={videoVariant}
        data-testid={suffixTestId('slideshowItem', props)}
        video={props.video}
      />
    </div>
  );
}
