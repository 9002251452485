import {colord} from 'colord';
import {useAnimatedPopper} from 'platform/components';
import styled from 'styled-components';

import {useRef} from 'react';

import {RequiredTestIdProps, suffixTestId, useOutsideClick} from 'shared';

import {Button} from './Button';

interface SelectProps extends RequiredTestIdProps {
  options: {
    label: string;
    value: string;
    isSelected?: boolean;
    onClick: () => void;
  }[];
  placeholder: string;
  value: string;
}

export function Select(props: SelectProps) {
  const ref = useRef<HTMLDivElement>(null);
  const {referenceRef, togglePopper, popperProps, Popper, closePopper, isOpen} = useAnimatedPopper({
    placement: 'bottom-start',
    gutter: 4,
    isOutsideClickIgnored: true,
  });

  useOutsideClick({
    ref,
    enabled: isOpen,
    handler: () => {
      closePopper();
    },
  });

  return (
    <div ref={ref}>
      <div ref={referenceRef}>
        <Button
          isActive={isOpen}
          onClick={togglePopper}
          data-testid={suffixTestId('select', props)}
          title={
            props.options.find((option) => option.value === props.value)?.label ?? props.placeholder
          }
        />
      </div>
      <Popper {...popperProps}>
        <StyledContent>
          {props.options.map((option) => (
            <Button
              key={option.value}
              title={option.label}
              variant="ghost"
              size="small"
              isFullWidth
              justifyContent="start"
              isActive={option.isSelected}
              onClick={() => {
                option.onClick();
                closePopper();
              }}
              data-testid={suffixTestId(`selectOption-${option.value}`, props)}
            />
          ))}
        </StyledContent>
      </Popper>
    </div>
  );
}

const StyledContent = styled.div`
  padding: ${({theme}) => theme.getSize(2)};
  border-radius: ${({theme}) => theme.radii.small};
  background-color: ${({theme}) => colord(theme.colors.general.black).alpha(0.7).toRgbString()};
  color: ${({theme}) => theme.colors.general.white};
`;
