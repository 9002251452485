import {useIntersectionObserver} from 'platform/components';
import {getPixelsFromRem, Integer} from 'platform/foundation';
import {css, useTheme} from 'styled-components';
import {match} from 'ts-pattern';

import {ReactNode} from 'react';

import {useBoolean} from 'shared';

interface SlideshowActionWrapperProps {
  children: ReactNode;
  position: 'right' | 'left';
  spacingFromEdge?: Integer;
  isHidden?: boolean;
}

export function SlideshowActionsWrapper(props: SlideshowActionWrapperProps) {
  const theme = useTheme();
  const [isVisible, setVisible, setHidden] = useBoolean(true);
  const observedElementsRefs = useIntersectionObserver(
    (info) => {
      if (info[0].isIntersecting) {
        setVisible();
      } else {
        setHidden();
      }
    },
    {threshold: 1, rootMargin: `-${getPixelsFromRem(2)}px`}
  );

  const leftStyle = match(props.position)
    .with('left', () => theme.getSize(props.spacingFromEdge ?? 0))
    .otherwise(() => 'auto');
  const rightStyle = match(props.position)
    .with('right', () => theme.getSize(props.spacingFromEdge ?? 0))
    .otherwise(() => 'auto');

  return (
    <div
      css={css`
        position: absolute;
        top: 0;
        height: 100%;
        pointer-events: none;
        left: ${leftStyle};
        right: ${rightStyle};
        z-index: ${({theme}) => theme.zIndices.LIGHTBOX_ACTIONS};
      `}
    >
      <div
        ref={(ref) => {
          observedElementsRefs.current[0] = ref;
        }}
        css={css`
          position: sticky;
          top: ${({theme}) => theme.getSize(2)};
          opacity: ${isVisible ? 1 : 0};
          transition: opacity 0.15s ease-out;
          padding-top: ${({theme}) => theme.getSize(2)};
          padding-bottom: ${({theme}) => theme.getSize(2)};
        `}
      >
        {props.children}
      </div>
    </div>
  );
}
